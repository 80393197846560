import { Modal } from '@vinted/web-ui'

import { PaymentAuthStateModel } from 'types/models'

import ExtraServicePaymentAuth from './ExtraServicePaymentAuth'

type Props = {
  show: boolean
  onAuthSubmitted: (state: PaymentAuthStateModel) => void
  onError: (errorMessage: string) => void
  onCancel: () => void
}

const ExtraServicePaymentAuthModal = ({ show, onError, onCancel, onAuthSubmitted }: Props) => {
  return (
    <Modal show={show}>
      <ExtraServicePaymentAuth
        onAuthSubmitted={onAuthSubmitted}
        onCancel={onCancel}
        onError={onError}
      />
    </Modal>
  )
}

export default ExtraServicePaymentAuthModal
