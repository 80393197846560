'use client'

import { useSelector } from 'react-redux'
import { Cell, Icon, Text, Validation } from '@vinted/web-ui'
import { Pencil24, Plus24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import ContentLoader from 'components/ContentLoader'
import { CreditCard } from 'components/CreditCardList'
import { PayInMethod } from 'components/PayInMethodSelection'
import { ClickableCreditCardItemType } from 'components/CreditCardList/types'

import { getLocale } from 'state/intl/selectors'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { PayInMethodCode } from 'constants/pay-in-method'
import { CreditCardModel, PayInMethodModel, WalletBalanceModel } from 'types/models'

type Props = {
  error?: string
  isLoading?: boolean
  walletBalance?: WalletBalanceModel | null
  activePayInMethod?: PayInMethodModel | null
  selectedCreditCard?: CreditCardModel | null
  onPaymentMethodSelect: () => void
}

const ExtraServiceActivePayInMethod = ({
  isLoading = false,
  error,
  walletBalance,
  activePayInMethod,
  selectedCreditCard,
  onPaymentMethodSelect,
}: Props) => {
  const translate = useTranslate('payment_method_selection')
  const locale = useSelector(getLocale)

  function renderPayInMethodBody() {
    if (activePayInMethod?.code === PayInMethodCode.Trustly) return activePayInMethod.note
    if (activePayInMethod?.code === PayInMethodCode.Wallet && walletBalance) {
      const { availableAmount } = walletBalance

      return (
        <Text
          text={translate('notes.wallet', {
            balance: formatCurrencyAmount(availableAmount, locale),
          })}
        />
      )
    }

    return null
  }

  function renderSuffix() {
    return (
      <Icon
        name={Pencil24}
        color={Icon.Color.GreyscaleLevel3}
        testId="extra-service-active-pay-in-method-pencil-icon"
      />
    )
  }

  function renderEmptyPayInMethod() {
    return (
      <Cell
        title={translate('choose_payment_method')}
        suffix={
          <Icon
            name={Plus24}
            aria={{
              'aria-hidden': 'true',
            }}
          />
        }
        validation={error && <Validation text={error} theme="warning" />}
        type={Cell.Type.Navigating}
        styling={Cell.Styling.Wide}
        testId="extra-service-active-pay-in-method-choose-payment-method"
        onClick={onPaymentMethodSelect}
      />
    )
  }

  if (isLoading) {
    return <ContentLoader testId="extra-service-active-pay-in-method-loader" />
  }

  if (selectedCreditCard) {
    return (
      <CreditCard
        card={selectedCreditCard}
        clickableType={ClickableCreditCardItemType.AlwaysClickable}
        onClick={onPaymentMethodSelect}
        suffix={renderSuffix()}
      />
    )
  }

  if (activePayInMethod) {
    return (
      <PayInMethod
        body={renderPayInMethodBody()}
        payInMethod={activePayInMethod}
        onClick={onPaymentMethodSelect}
        suffix={renderSuffix()}
      />
    )
  }

  return renderEmptyPayInMethod()
}

export default ExtraServiceActivePayInMethod
