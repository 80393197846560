'use client'

import { useMemo, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { Button, Cell, Icon, Loader, Modal, Spacer, Text } from '@vinted/web-ui'
import { CancelCircleFilled32, CheckCircleFilled32 } from '@vinted/monochrome-icons'

import { useIntl } from 'react-intl'

import FaqEntryUrl from 'components/FaqEntryUrl'

import useAbTest from 'hooks/useAbTest'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking/useTracking'

import { getLocale } from 'state/intl/selectors'

import { CurrencyAmountModel } from 'types/models'
import { clickEvent } from 'libs/common/event-tracker/events'
import { formatCurrencyAmount } from 'libs/utils/formatString'

import { AccessChannel } from 'constants/index'
import { FaqEntryType } from 'constants/faq-entry'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import {
  ExtraServiceOrderType,
  ExtraServiceOrderStatus,
  VAS_PENDING_STATE_AB_TEST_EXTENDED_PAYMENT_POLL_DURATION,
} from 'constants/extra-service'

import usePendingPaymentCountdown from './usePendingPaymentCountdown'

import useTrackViewExtraServiceCheckout from '../hooks'

type Props = {
  orderId?: number
  errorMessage?: string
  orderPayable?: CurrencyAmountModel
  show: boolean
  orderType: ExtraServiceOrderType
  orderStatus: ExtraServiceOrderStatus
  onContinue: () => void
}

const ACTIONABLE_STATUSES = [ExtraServiceOrderStatus.Failure, ExtraServiceOrderStatus.Success]

const ExtraServiceOrderStatusModal = ({
  show,
  orderId,
  orderType,
  orderStatus,
  errorMessage,
  orderPayable,
  onContinue,
}: Props) => {
  const intl = useIntl()
  const { track } = useTracking()
  const locale = useSelector(getLocale)
  const translate = useTranslate(`extra_services.checkout.order_status_modal.${orderType}`)
  const translateCommon = useTranslate('extra_services.checkout.order_status_modal.common')

  const VASPendingStateAbTestVariant =
    useAbTest({
      shouldTrackExpose: show,
      abTestName: 'vas_pending_state',
    })?.variant || 'off'
  const isVASPendingStateAbTestEnabled = VASPendingStateAbTestVariant !== 'off'

  const countdownDuration =
    VAS_PENDING_STATE_AB_TEST_EXTENDED_PAYMENT_POLL_DURATION[VASPendingStateAbTestVariant]
  const countdown = usePendingPaymentCountdown({
    start:
      show && isVASPendingStateAbTestEnabled && orderStatus === ExtraServiceOrderStatus.Processing,
    duration: countdownDuration,
  })

  useTrackViewExtraServiceCheckout({
    orderId,
    orderType,
    screen: Screen.ExtraServicePendingPaymentModal,
    shouldTrack: show && countdown.state === 'inProgress',
  })

  // TODO: If vas_pending_state ab test is scaled, move this logic to a separate components for each payment state.
  const commonOverridenContent = useMemo(() => {
    if (!isVASPendingStateAbTestEnabled) return null

    if (orderStatus === ExtraServiceOrderStatus.Processing && countdown.state !== 'idle') {
      const formattedCountdownDuration = intl.formatDate(new Date(countdownDuration), {
        second: '2-digit',
      })

      return {
        title: translateCommon('long_processing.title'),
        action: translateCommon('long_processing.action'),
        explanation:
          countdown.state === 'inProgress'
            ? translateCommon('long_processing.explanation.countdown', {
                countdownDuration: formattedCountdownDuration,
              })
            : translateCommon('long_processing.explanation.continue_waiting'),
      }
    }

    if (orderStatus === ExtraServiceOrderStatus.Failure) {
      const values = {
        'help-center': (chunks: Array<ReactNode>) => (
          <FaqEntryUrl
            type={FaqEntryType.PaymentFailed}
            accessChannel={AccessChannel.ProductLink}
            render={url => (
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                data-testid="failed-order-explanation-faq-link"
              >
                {chunks}
              </a>
            )}
          />
        ),
      }

      return {
        title: translateCommon(`${orderStatus}.title`),
        explanation: translateCommon(`${orderStatus}.explanation`, values),
        action: translateCommon(`${orderStatus}.action`),
      }
    }

    return null
  }, [
    intl,
    orderStatus,
    countdown.state,
    translateCommon,
    countdownDuration,
    isVASPendingStateAbTestEnabled,
  ])

  function handleOnContinue() {
    if (isVASPendingStateAbTestEnabled && countdown.state !== 'inProgress') {
      track(
        clickEvent({
          screen: Screen.ExtraServicePendingPaymentModal,
          target: ClickableElement.ExtraServicePendingPaymentTryAgain,
          targetDetails: JSON.stringify({
            type: orderType,
            service_order_id: orderId,
          }),
        }),
      )
    }

    onContinue()
  }

  function renderBottomPollingNote() {
    if (countdown.state === 'idle') return null
    if (orderStatus !== ExtraServiceOrderStatus.Processing) return null

    const formattedCountdown =
      countdown.state === 'inProgress'
        ? intl.formatDate(new Date(countdown.remainingTime), {
            minute: '2-digit',
            second: '2-digit',
          })
        : null
    const content =
      countdown.state === 'inProgress'
        ? translateCommon('long_processing.bottom_note.countdown', {
            countdown: formattedCountdown,
          })
        : translateCommon('long_processing.bottom_note.continue_waiting')

    return (
      <>
        <Spacer size={Spacer.Size.Large} />
        <Text
          text={content}
          type={Text.Type.Caption}
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
        />
      </>
    )
  }

  function renderActions() {
    const isLongPending =
      isVASPendingStateAbTestEnabled &&
      orderStatus === ExtraServiceOrderStatus.Processing &&
      countdown.state !== 'idle'
    const isActionable = ACTIONABLE_STATUSES.includes(orderStatus)

    if (!isActionable && !isLongPending) return null

    return (
      <Button
        disabled={isLongPending && countdown.state !== 'over'}
        text={commonOverridenContent?.action || translate(`${orderStatus}.action`)}
        styling={Button.Styling.Filled}
        onClick={handleOnContinue}
        testId="continue-button"
      />
    )
  }

  function renderIcon() {
    switch (orderStatus) {
      case ExtraServiceOrderStatus.Success:
        return (
          <Icon
            name={CheckCircleFilled32}
            testId="extra-service-order-success-icon"
            color={Icon.Color.Success}
          />
        )
      case ExtraServiceOrderStatus.Failure:
        return (
          <Icon
            name={CancelCircleFilled32}
            testId="extra-service-order-failure-icon"
            color={Icon.Color.Warning}
          />
        )
      case ExtraServiceOrderStatus.Processing:
      default:
        return <Loader testId="extra-service-order-confirmation-loader" size={Loader.Size.XLarge} />
    }
  }

  if (orderStatus === ExtraServiceOrderStatus.Idle) return null

  const formattedOrderPayable = orderPayable && formatCurrencyAmount(orderPayable, locale)

  return (
    <Modal show={show} testId="extra-service-order-status-modal">
      <Cell>
        <Spacer size={Spacer.Size.Large} />
        <div className="u-flexbox u-justify-content-center">{renderIcon()}</div>
        <Spacer size={Spacer.Size.X2Large} />
        <Text
          text={
            commonOverridenContent?.title ||
            translate(`${orderStatus}.title`, {
              orderPayable: formattedOrderPayable,
            })
          }
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
          type={Text.Type.Heading}
        />
        <Spacer size={Spacer.Size.Large} />
        <Text
          text={
            errorMessage ||
            commonOverridenContent?.explanation ||
            translate(`${orderStatus}.explanation`)
          }
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
        />
        <Spacer size={Spacer.Size.X2Large} />
        {renderActions()}
        {renderBottomPollingNote()}
      </Cell>
    </Modal>
  )
}

export default ExtraServiceOrderStatusModal
