import { useRef, useEffect } from 'react'

import { ExtraServiceOrderType } from 'constants/extra-service'
import { CreditCardAddReturnResult } from 'constants/credit-card'
import useLocation from 'hooks/useLocation'
import { removeRedirectUrlParams, parseExtraServiceCardAddRedirectUrl } from 'libs/utils/threeDS2'

type CardAuthSuccessResult = (redirectData: { cardId: string; orderId: number }) => void
type CardAuthFailureResult = (redirectData: { orderId: number }) => void
type CardAuthNoResult = () => void
type CardAuthRedirectResultCallbacks = {
  onSuccess: CardAuthSuccessResult
  onFailure: CardAuthFailureResult
  onNoResult?: CardAuthNoResult
}

const useExtraServiceCardAuthRedirectResultEffect = (
  orderType: ExtraServiceOrderType,
  callbacks: CardAuthRedirectResultCallbacks,
) => {
  const { relativeUrl, replaceHistoryState, urlQuery } = useLocation()
  const onSuccessRef = useRef(callbacks.onSuccess)
  const onFailureRef = useRef(callbacks.onFailure)
  const onNoResultRef = useRef(callbacks.onNoResult)
  const urlQueryRef = useRef(urlQuery)

  onSuccessRef.current = callbacks.onSuccess
  onFailureRef.current = callbacks.onFailure
  onNoResultRef.current = callbacks.onNoResult
  urlQueryRef.current = urlQuery

  useEffect(() => {
    const { cardId, orderId, cardAddResult } = parseExtraServiceCardAddRedirectUrl(
      urlQueryRef.current,
      orderType,
    )

    if (!cardAddResult || !orderId) {
      onNoResultRef.current?.()

      return
    }

    if (cardAddResult === CreditCardAddReturnResult.Success && cardId) {
      onSuccessRef.current({ cardId, orderId })
    } else if (cardAddResult === CreditCardAddReturnResult.Fail) {
      onFailureRef.current({ orderId })
    }

    replaceHistoryState(removeRedirectUrlParams(relativeUrl, urlQueryRef.current))
  }, [orderType, relativeUrl, replaceHistoryState])
}

export default useExtraServiceCardAuthRedirectResultEffect
