'use client'

import { useSelector } from 'react-redux'
import { Button, Modal, Navigation } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { Screen } from 'constants/tracking/screens'
import { ExtraServiceOrderType } from 'constants/extra-service'

import { PayInMethodSelection } from 'components/PayInMethodSelection'
import ContentLoader from 'components/ContentLoader'

import { PayInMethodCode } from 'constants/pay-in-method'
import { PayInMethodModel, CurrencyAmountModel } from 'types/models'

import { State as AppState } from 'state/types'
import * as extraServiceSelectors from 'state/extra-service/selectors'

import useTranslate from 'hooks/useTranslate'

import useTrackViewExtraServiceCheckout from '../hooks'

type Props = {
  orderId?: number
  selectedCreditCardId?: string
  selectedPayInMethodId?: number
  orderType: ExtraServiceOrderType
  orderPayable?: CurrencyAmountModel
  show: boolean
  onBack: () => void
  onCancel: () => void
  onConfirm: (selectedPayInMethod: PayInMethodModel, creditCardId?: string) => void
  onAddCreditCard: (creditCardPayInMethod: PayInMethodModel) => void
}

const ExtraServicePayInMethodSelectionModal = ({
  show,
  orderId,
  orderType,
  orderPayable,
  selectedCreditCardId,
  selectedPayInMethodId,
  onBack,
  onCancel,
  onConfirm,
  onAddCreditCard,
}: Props) => {
  const translateModal = useTranslate('checkout.payment.select')
  const translatePayIntMethodSelection = useTranslate('payment_method_selection')

  const payInMethods = useSelector(extraServiceSelectors.getPayInMethods)
  const walletBalance = useSelector(extraServiceSelectors.getWalletBalance)
  const creditCards = useSelector(extraServiceSelectors.getPermanentCreditCards)
  const isWalletBalanceInsufficient = useSelector((state: AppState) =>
    extraServiceSelectors.getIsWalletBalanceInsufficient(state, orderPayable?.amount),
  )

  useTrackViewExtraServiceCheckout({
    shouldTrack: show,
    orderId,
    orderType,
    screen: Screen.PaymentOptions,
  })

  function handleCancel() {
    onCancel()
  }

  function handleBack() {
    onBack()
  }

  function handleAddCreditCard(method: PayInMethodModel) {
    onAddCreditCard(method)
  }

  function handleMethodConfirmed(method: PayInMethodModel, creditCardId?: string) {
    if (method.code === PayInMethodCode.CreditCard && !creditCardId) {
      handleAddCreditCard(method)

      return
    }

    onConfirm(method, creditCardId)
  }

  function renderNavigation() {
    return (
      <Navigation
        body={translateModal('title')}
        right={
          <Button
            inline
            iconName={X24}
            styling={Button.Styling.Flat}
            testId="extra-service-pay-in-method-selection-modal-close"
            onClick={handleCancel}
          />
        }
      />
    )
  }

  function renderPayInMethodSelection() {
    if (!payInMethods.length) {
      return (
        <ContentLoader
          styling={ContentLoader.Styling.Wide}
          testId="extra-service-pay-in-methods-loader"
        />
      )
    }

    const walletAvailability = {
      balance: walletBalance || null,
      isDisabled: isWalletBalanceInsufficient,
      reason: translatePayIntMethodSelection('validations.insufficient_wallet_funds'),
    }

    return (
      <PayInMethodSelection
        wrapInScrollableArea
        creditCards={creditCards}
        wallet={walletAvailability}
        payInMethods={payInMethods}
        selectedCreditCardId={selectedCreditCardId}
        selectedPayInMethodId={selectedPayInMethodId}
        trackTargetDetails={{ type: orderType, service_order_id: orderId }}
        onBack={handleBack}
        onSubmit={handleMethodConfirmed}
        onAddNewCard={handleAddCreditCard}
      />
    )
  }

  return (
    <Modal show={show} hasScrollableContent testId="extra-service-pay-in-method-selection-modal">
      <div className="u-fill-width">
        {renderNavigation()}
        {renderPayInMethodSelection()}
      </div>
    </Modal>
  )
}

export default ExtraServicePayInMethodSelectionModal
