import { useRef, useState, useEffect, useCallback } from 'react'

import { MS_PER_SECOND } from 'constants/date'

type Countdown =
  | {
      state: 'idle' | 'over'
    }
  | {
      state: 'inProgress'
      remainingTime: number
    }

const COUNTDOWN_INTERVAL = MS_PER_SECOND
const COUNTDOWN_START_DELAY = 5 * MS_PER_SECOND

const usePendingPaymentCountdown = ({ start, duration }: { duration?: number; start: boolean }) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const initialTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [countdown, setCountdown] = useState<Countdown>({
    state: 'idle',
  })

  const handleInterval = useCallback(() => {
    setCountdown(prevCountdown => {
      if (!duration) return prevCountdown

      // TODO: if test is successful, make duration constant and remove this ternary.
      const remainingTime =
        prevCountdown.state === 'inProgress' ? prevCountdown.remainingTime : duration

      return {
        remainingTime: remainingTime - COUNTDOWN_INTERVAL,
        state: remainingTime > COUNTDOWN_INTERVAL ? 'inProgress' : 'over',
      }
    })
  }, [duration])

  const stopCountdown = useCallback(() => {
    if (!intervalRef.current) return

    clearInterval(intervalRef.current)
    intervalRef.current = null
  }, [])

  const startCountdown = useCallback(() => {
    if (intervalRef.current) return

    intervalRef.current = setInterval(handleInterval, COUNTDOWN_INTERVAL)
  }, [handleInterval])

  const cancelInitialTimeout = useCallback(() => {
    if (!initialTimeoutRef.current) return

    clearTimeout(initialTimeoutRef.current)
    initialTimeoutRef.current = null
  }, [])

  const scheduleCountdown = useCallback(() => {
    if (initialTimeoutRef.current) return

    initialTimeoutRef.current = setTimeout(startCountdown, COUNTDOWN_START_DELAY)
  }, [startCountdown])

  useEffect(() => {
    if (start && duration) {
      scheduleCountdown()
    } else {
      stopCountdown()
      cancelInitialTimeout()
      setCountdown({ state: 'idle' })
    }

    return () => {
      stopCountdown()
      cancelInitialTimeout()
      setCountdown({ state: 'idle' })
    }
  }, [start, duration, stopCountdown, startCountdown, scheduleCountdown, cancelInitialTimeout])

  useEffect(() => {
    if (countdown.state === 'inProgress') return

    stopCountdown()
  }, [stopCountdown, countdown.state])

  return countdown
}

export default usePendingPaymentCountdown
