import { useEffect } from 'react'

import { Screen } from 'constants/tracking/screens'
import { ExtraServiceOrderType } from 'constants/extra-service'

import useTracking from 'hooks/useTracking/useTracking'

import { buyerViewCheckoutEvent } from 'libs/common/event-tracker/events'

const useTrackViewExtraServiceCheckout = ({
  screen,
  orderId,
  orderType,
  shouldTrack,
}: {
  orderId?: number
  screen: Screen
  shouldTrack: boolean
  orderType: ExtraServiceOrderType
}) => {
  const { track } = useTracking()

  useEffect(() => {
    if (!orderId || !shouldTrack) return

    track(buyerViewCheckoutEvent({ screen, orderId, orderType }))
  }, [track, screen, orderId, orderType, shouldTrack])
}

export default useTrackViewExtraServiceCheckout
