'use client'

import { useSelector } from 'react-redux'

import useTranslate from 'hooks/useTranslate'
import usePageId from 'hooks/usePageId'

import { getUser } from 'state/session/selectors'

import { CreditCardFormModal } from 'components/CreditCardForm'

import { getCreditAddCardSource } from 'libs/utils/threeDS2'

import { CreditCardModel } from 'types/models'
import { ExtraServiceAddCardSourceParams } from 'types/threeDS2'

import { Screen } from 'constants/tracking/screens'
import { ExtraServiceOrderType } from 'constants/extra-service'

import useTrackViewExtraServiceCheckout from '../hooks'

type PrefilledParams = 'page' | 'userId' | 'orderId'

// Allows to apply Omit to each element of a union type.
// By default Omit does not apply its' effect on each type of a union type.
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#distributive-conditional-types
type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never

type Props = {
  orderId?: number
  isCardAddError?: boolean
  show: boolean
  orderType: ExtraServiceOrderType
  addCardSourceParams: DistributiveOmit<ExtraServiceAddCardSourceParams, PrefilledParams>
  onSuccess: (creditCard: CreditCardModel) => void
  onBack: () => void
}

const ExtraServiceCreditCardFormModal = ({
  show,
  onBack,
  orderId,
  orderType,
  onSuccess,
  addCardSourceParams,
  isCardAddError = false,
}: Props) => {
  const translateRedirectError = useTranslate('credit_card_add.threeds2.errors')

  const user = useSelector(getUser)

  const currentPage = usePageId()

  const errorMessage = isCardAddError ? translateRedirectError('redirect_return_error') : undefined

  useTrackViewExtraServiceCheckout({
    shouldTrack: show,
    orderId,
    orderType,
    screen: Screen.CreditCardAdd,
  })

  if (!user || !orderId) return null

  return (
    <CreditCardFormModal
      show={show}
      allowSingleUse
      errorMessage={errorMessage}
      source={getCreditAddCardSource({
        orderId,
        userId: user.id,
        page: currentPage,
        ...addCardSourceParams,
      })}
      trackingTargetDetails={{
        service_order_id: orderId,
        type: orderType,
      }}
      onCreditCardAddSuccess={onSuccess}
      onBack={onBack}
    />
  )
}

export default ExtraServiceCreditCardFormModal
