'use client'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Loader, Navigation } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { PaymentAuthStateModel } from 'types/models'

import { getPaymentAuth, getThreeDS2Configuration } from 'state/extra-service/selectors'

import PaymentAuth from 'components/PaymentAuth'

type Props = {
  onAuthSubmitted: (state: PaymentAuthStateModel) => void
  onError: (errorMessage: string) => void
  onCancel: () => void
}

const ExtraServicePaymentAuth = ({ onError, onCancel, onAuthSubmitted }: Props) => {
  const paymentAuth = useSelector(getPaymentAuth)
  const threeDS2Config = useSelector(getThreeDS2Configuration)

  const [isLoading, setIsLoading] = useState(true)

  function handleInputRequested() {
    setIsLoading(false)
  }

  function renderLoader() {
    if (!isLoading) return null

    return (
      <div className="threeds2__extra-service-loader" data-testid="3ds-loader">
        <Loader size={Loader.Size.X2Large} />
      </div>
    )
  }

  const authAction = paymentAuth.embedded?.authAction

  if (!authAction || !threeDS2Config) return null

  return (
    <div className="threeds2__challenge-modal">
      <Navigation
        right={
          <Button
            inline
            iconName={X24}
            styling={Button.Styling.Flat}
            testId="extra-service-payment-auth-modal--close"
            onClick={onCancel}
          />
        }
      />
      {renderLoader()}
      <PaymentAuth
        authAction={authAction}
        threeDS2Config={threeDS2Config}
        onInputRequested={handleInputRequested}
        onContinue={onAuthSubmitted}
        onError={onError}
      />
    </div>
  )
}

export default ExtraServicePaymentAuth
